html {
  height: 100%;
  scroll-behavior: smooth;
}

body,
#root,
.main {
  min-height: 100vh;
}

a {
  color: #b35253;
}

.right {
  padding-right: 2px !important;
  padding-left: 2px !important;
}

.ui.header,
.ui.text.container {
  font-family: inherit !important;
}

.navArrow {
  cursor: pointer;
  color: #b35253;
  font-size: 22px;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.zeroTier {
  font-weight: bold;
}

.firstTier {
  padding-left: 15px;
}

.secondTier {
  padding-left: 30px;
}

.thirdTier {
  padding-left: 45px;
}

.fourthTier {
  padding-left: 60px;
}

.current {
  color: #878e76;
}

.clickable:hover {
  cursor: pointer;
}

.acknowledgment {
  font-size: 9pt;
  padding-left: 10px;
  padding-bottom: 10px;
}

.acknowledgment p {
  margin-bottom: 0px;
}

.noselect {
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.usccb-bible-text .verse-number {
  padding-right: 5px;
}

.usccb-bible-text .verse-number:before {
  content: " ";
  display: block;
}

.usccb-bible-text .non-leading-bold-header:before {
  content: " ";
  display: block;
  margin-top: 20px;
}

.usccb-bible-text .footer-area {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid;
  font-size: 14px;
}
